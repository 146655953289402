import ApiService from "./api.service"

export const LinkInfosService = {
  // async get({ topicId, offset: offset = 0, limit: limit = 10, q }) {
  //   let response = null
  //   try {
  //     response = await ApiService.query("/link-infos", {
  //       params: { topicId, offset, limit, q },
  //     })
  //     response = response.data.data
  //   } catch (error) {
  //     response = null
  //   }
  //   return response
  // },
  // async create(data) {
  //   let response = null
  //   try {
  //     response = await ApiService.post("/link-infos", data)
  //   } catch (error) {
  //     response = null
  //   }
  //   return response
  // },
  async update({ source, username, postDate, intention, text, linkId, fanCount }) {
    let response = null
    try {
      response = await ApiService.put(`/link-infos/${linkId}`, {
        source,
        username,
        postDate,
        intention,
        text,
        linkId,
        fanCount,
      })
    } catch (error) {
      response = {
        error: error.response.data,
      }
    }
    return response
  },
  async forceUpdate(data) {
    let response = null
    try {
      response = await ApiService.put(`/link-infos/force-update`, data)
      response = response.data
    } catch (error) {
      response = {
        error: error.response.data,
      }
    }
    return response
  },
}
