import ApiService from "./api.service";

export const SummaryService = {
  async get({
    condition: condition = {},
    offset: offset = 0,
    limit: limit = 10
  }) {
    let response = await ApiService.query("/summaries", {
      params: {
        ...condition,
        limit,
        offset
      }
    });
    return response.data.data;
  },
  async getById(summaryId) {
    let response = null;
    try {
      response = await ApiService.get("/summaries", summaryId);
      response = response.data.data;
    } catch (error) {
      response = {
        error: error.response.data
      };
    }

    return response;
  },
  async create({
    topicId,
    isSum,
    isFirst,
    from,
    to,
    mode,
    comment,
    sentiment,
    isShowSentiment
  }) {
    let response = null;
    try {
      response = await ApiService.post("/summaries", {
        topicId,
        isSum,
        isFirst,
        from,
        to,
        mode,
        comment,
        sentiment,
        isShowSentiment
      });
      response = response.data.data;
    } catch (error) {
      response = {
        error: error.response.data
      };
    }
    return response;
  },
  async update(data) {
    let response = null;
    try {
      response = await ApiService.put(`/summaries/${data.summaryId}`, data);
      response = response.data.data;
    } catch (error) {
      response = {
        error: error.response.data
      };
    }
    return response;
  },
  async delete(summaryId) {
    let response = null;
    try {
      response = await ApiService.delete(`/summaries/${summaryId}`);
    } catch (error) {
      response = {
        error: error.response.data
      };
    }
    return response;
  },
  async preview({
    topicId,
    from,
    to,
    comment,
    isFirst,
    summaryId,
    previousSummaryId
  }) {
    let response = null;
    try {
      response = await ApiService.post("/summaries/preview", {
        topicId,
        from,
        to,
        comment,
        isFirst,
        summaryId,
        previousSummaryId
      });
      response = response.data.data;
    } catch (error) {
      response = {
        error: error.response.data
      };
    }
    return response;
  },

  async createSnapshot(data) {
    let response = null;
    try {
      response = await ApiService.post(
        `/summaries/snapshot/${data.summaryId}`,
        data
      );
      response = response.data;
    } catch (error) {
      response = {
        error: error.response.data
      };
    }
    return response;
  }
};
