<template>
  <b-button
    size="sm"
    variant="light"
    class="btn-icon mx-1"
    @click="$emit('click')"
  >
    <span class="svg-icon svg-icon-md svg-icon-primary">
      <inline-svg src="media/svg/icons/Design/Edit.svg"></inline-svg>
    </span>
  </b-button>
</template>

<script>
export default {}
</script>

<style></style>
