import ApiService from "./api.service"

export const UtilsService = {
  async getLines() {
    let response = await ApiService.get("/utils/lines")
    return response.data.data
  },
  async getMediaInfluencers() {
    let response = await ApiService.get("/utils/media-influencers")
    return response.data.data
  },
  async getIntentions() {
    let response = await ApiService.get("/utils/intentions")
    return response.data.data
  },
  async getTopicLinkSuggestion({ topicId, link, postType }) {
    let response = null
    try {
      response = await ApiService.query("/utils/topics/link-suggestion", {
        params: {
          topicId,
          link,
          postType
        },
        timeout: 60000
      })
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response ? error.response.data : error
      }
    }
    return response
  },
  async getBrandLinkSuggestion({ cusId, link, postType }) {
    let response = null
    try {
      response = await ApiService.query("/utils/brands/link-suggestion", {
        params: {
          cusId,
          link,
          postType
        },
        timeout: 60000
      })
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response ? error.response.data : error
      }
    }
    return response
  },
  async getAutoUpdateStatus() {
    let response = null
    try {
      response = await ApiService.query("/utils/auto-update-status")
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async getDOMCategory(account) {
    let response = null
    try {
      response = await ApiService.query("/utils/dom-category", {
        params: {
          account
        }
      })
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async getDOMAccount() {
    let response = null
    try {
      response = await ApiService.get("/utils/dom-account")
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  }
}
