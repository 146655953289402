import ApiService from "./api.service"

export const MediaInfluencerService = {
  async get({
    condition: condition = {},
    offset: offset = 0,
    limit: limit = 10,
    q,
  }) {
    let response = await ApiService.query("/media-influencers", {
      params: {
        ...condition,
        limit,
        offset,
        q,
      },
    })
    return response.data.data
  },

  async create({ name, keywords }) {
    let response = null
    try {
      response = await ApiService.post("/media-influencers", {
        name,
        keywords,
      })
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response.data,
      }
    }
    return response
  },
  async update({ mediaId, name, keywords }) {
    let response = null
    try {
      response = await ApiService.put(`/media-influencers/${mediaId}`, {
        name,
        keywords,
      })
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response.data,
      }
    }
    return response
  },
  async delete({ mediaId }) {
    let response = null
    try {
      response = await ApiService.delete(`/media-influencers/${mediaId}`)
    } catch (error) {
      response = {
        error: error.response.data,
      }
    }
    return response
  },
}
