import Vue from "vue"
import ConfirmModal from "./ConfirmModal"
import BadgeStatus from "./BadgeStatus"
import ButtonEdit from "./ButtonEdit"
import ButtonDelete from "./ButtonDelete"
import ButtonAdd from "./ButtonAdd"
import ButtonExpand from "./ButtonExpand"
import ButtonView from "./ButtonView"

Vue.component("confirm-delete", ConfirmModal)
Vue.component("badge-status", BadgeStatus)
Vue.component("button-edit", ButtonEdit)
Vue.component("button-delete", ButtonDelete)
Vue.component("button-add", ButtonAdd)
Vue.component("button-expand", ButtonExpand)
Vue.component("button-view", ButtonView)
