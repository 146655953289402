import ApiService from "./api.service";

export const CustomerService = {
  async get({ offset: offset = 0, limit: limit = 10, order_by }) {
    let response = await ApiService.query("/customers", {
      params: {
        limit,
        offset,
        order_by
      }
    });
    return response.data.data;
  },
  async getById(cusId) {
    let response = await ApiService.get("/customers", cusId);
    return response.data.data;
  },
  async create(data) {
    let response = null;
    try {
      response = await ApiService.post("/customers", data);
    } catch (error) {
      response = {
        error: error.response.data
      };
    }
    return response;
  },
  async update(data) {
    let response = null;
    try {
      response = await ApiService.put(`/customers/${data.cusId}`, data);
    } catch (error) {
      response = {
        error: error.response.data
      };
    }
    return response;
  },
  async delete(cusId) {
    let response = null;
    try {
      response = await ApiService.delete(`/customers/${cusId}`);
    } catch (error) {
      response = {
        error: error.response.data
      };
    }
    return response;
  }
};
