import ApiService from "./api.service"

export const TopicService = {
  async get({ cusId, offset: offset = 0, limit: limit = 10, q }) {
    let response = await ApiService.query("/topics", {
      params: { cusId, offset, limit, q },
    })
    return response.data.data
  },
  async create(data) {
    let response = null
    try {
      response = await ApiService.post("/topics", data)
    } catch (error) {
      response = {
        error: error.response.data,
      }
    }
    return response
  },
  async update(data) {
    let response = null
    try {
      response = await ApiService.put(`/topics/${data.topicId}`, data)
    } catch (error) {
      response = {
        error: error.response.data,
      }
    }
    return response
  },
  async delete({ cusId, topicId }) {
    let response = null
    try {
      response = await ApiService.delete(`/topics/${topicId}`, {
        params: {
          cusId,
        },
      })
    } catch (error) {
      response = {
        error: error.response.data,
      }
    }
    return response
  },
}
