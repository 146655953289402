import ApiService from "./api.service"

export const LineIncidentLogsService = {
  async get({ cusId, competitorId, offset: offset = 0, limit: limit = 10, q }) {
    let response = await ApiService.query("/line-incident-logs", {
      params: { cusId, competitorId, offset, limit, q }
    })
    return response.data.data
  },
  async create(data) {
    let response = null
    try {
      response = await ApiService.post("/line-incident-logs", data)
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async update(logId, data) {
    let response = null
    try {
      response = await ApiService.put(`/line-incident-logs/${logId}`, data)
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async delete(_id) {
    let response = null
    try {
      response = await ApiService.delete(`/line-incident-logs/${_id}`)
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async resolve({ cusId, link, forceResolve, postId }) {
    let response = null
    try {
      response = await ApiService.post(`/line-incident-logs/resolve`, {
        cusId,
        link,
        forceResolve,
        postId
      })
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },

  async downloadRangeEngagement({
    cusId,
    competitorId,
    overallFromDate,
    overallToDate,
    weekFromDate,
    weekToDate
  }) {
    let response = null
    try {
      response = await ApiService.query("/line-incident-logs/export", {
        responseType: "blob",
        params: {
          cusId,
          competitorId,
          overallFromDate,
          overallToDate,
          weekFromDate,
          weekToDate
        }
      })
      let filename = response.headers.filename
      response = { blob: new Blob([response.data]), filename }
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async exportSummary({ cusId, competitorId, startDate, endDate }) {
    let response = null
    try {
      return await ApiService.query("/line-incident-logs/export-summary", {
        params: {
          cusId,
          competitorId,
          startDate,
          endDate
        }
      })
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async resend(logId) {
    let response = null
    try {
      response = await ApiService.put(`/line-incident-logs/${logId}/resend`)
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  }
}
