<template>
  <b-modal
    :id="modalId"
    ref="modal"
    title="Confirm"
    @ok="onDelete"
    ok-variant="danger"
    ok-title="Delete"
    :ok-disabled="isProcessing"
  >
    Do you want to delete this?
  </b-modal>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    modalId: String,
    removeFn: Function,
  },
  data() {
    return {
      isProcessing: false,
    }
  },
  methods: {
    async onDelete(e) {
      e.preventDefault()
      this.isProcessing = true
      let data = await this.removeFn()
      if (data) {
        this.$emit("ok")
        this.$bvModal.hide(this.modalId)
        this.$root.$bvToast.toast("Delete success", {
          title: `Success`,
          variant: "success",
          solid: true,
        })
      } else {
        this.$root.$bvToast.toast("Delete fail", {
          title: `Error`,
          variant: "error",
          solid: true,
        })
      }
      this.isProcessing = false
    },
  },
}
</script>

<style></style>
