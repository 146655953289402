import ApiService from "./api.service"

export const TopicLinksService = {
  async get({ topicId, offset: offset = 0, limit: limit = 10, q }) {
    let response = null
    try {
      response = await ApiService.query("/topic-links", {
        params: { topicId, offset, limit, q }
      })
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async create(data) {
    let response = null
    try {
      response = await ApiService.post("/topic-links", data)
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async update(data) {
    let response = null
    try {
      response = await ApiService.put(`/topic-links/${data._id}`, data)
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async delete(_id) {
    let response = null
    try {
      response = await ApiService.delete(`/topic-links/${_id}`)
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },

  async resolve({ topicId, link, postId }) {
    let response = null
    try {
      response = await ApiService.post("/topic-links/resolve", {
        topicId,
        link,
        postId
      })
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },

  async downloadEngagement({ topicId }) {
    let response = null
    try {
      response = await ApiService.query("/topic-links/export", {
        responseType: "blob",
        params: {
          topicId
        }
      })
      let filename = response.headers.filename
      response = { blob: new Blob([response.data]), filename }
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async downloadRangeEngagement({ topicId, toHourly, fromHourly }) {
    let response = null
    try {
      response = await ApiService.query("/topic-links/export/hourly", {
        responseType: "blob",
        params: {
          topicId,
          toHourly,
          fromHourly
        }
      })
      let filename = response.headers.filename
      response = { blob: new Blob([response.data]), filename }
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async duplicateLinksToTopic({ topicId, linkIds }) {
    let response = null
    try {
      response = await ApiService.post("/topic-links/duplicate", {
        topicId,
        linkIds
      })
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },

  async downloadRangeEngagementWithDOM({
    topicId,
    from,
    to,
    account,
    category
  }) {
    let response = null
    try {
      response = await ApiService.post(
        "/topic-links/export-with-dom",
        {
          topicId,
          from,
          to,
          account,
          category
        },
        {
          responseType: "blob"
        }
      )
      let filename = response.headers.filename
      response = { blob: new Blob([response.data]), filename }
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async getHourlyUpdatedCount(topicId) {
    let response = null
    try {
      response = await ApiService.query("/topic-links/hourly-updated-count", {
        params: { topicId }
      })
      response = response.data.data
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  }
}
