import ApiService from "./api.service"

export const CompetitorService = {
  async get(cusId, { offset: offset = 0, limit: limit = 10, order_by }) {
    let response = await ApiService.query("/competitors", {
      params: { cusId, limit, offset, order_by }
    })
    return response.data.data
  },
  async create(data) {
    let response = null
    try {
      response = await ApiService.post("/competitors", data)
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async update(data) {
    let response = null
    try {
      response = await ApiService.put(`/competitors/${data.competitorId}`, data)
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  },
  async delete(competitorId) {
    let response = null
    try {
      response = await ApiService.delete(`/competitors/${competitorId}`)
    } catch (error) {
      response = {
        error: error.response.data
      }
    }
    return response
  }
}
