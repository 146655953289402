import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"

import auth from "./auth.module"
import htmlClass from "./htmlclass.module"
import config from "./config.module"
import breadcrumbs from "./breadcrumbs.module"
import share from "./share.module"
import topic from "./topic.module"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  // reducer: (state) => ({ share: state.share }),
  modules: ["share", "auth"],
})

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    share,
    topic,
  },
  plugins: [vuexLocal.plugin],
})
