<template>
  <span class="font-size-xs">
    <b-badge pill :variant="variant" class="mr-1" size="sm">{{
      this.statusText
    }}</b-badge>
  </span>
</template>

<script>
export default {
  name: "BadgeStatus",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusText() {
      return this.active ? "Active" : "Inactive"
    },
    variant() {
      return this.active ? "success" : "danger"
    },
  },
}
</script>

<style></style>
