import merge from "deepmerge";
import objectPath from "object-path";

export const SET_SHARE_DATA = "setShareData";

export default {
  state: {
    share: {
      topicBrandSelectId: null,
      alertBrandSelectId: null,
      alertCompetitorSelectId: null,

      alertBrandSelect: null,
      alertCompetitorSelect: null
    }
  },
  getters: {
    shareData: state => (path, defaultValue) => {
      return objectPath.get(state.share, path, defaultValue);
    }
  },
  mutations: {
    [SET_SHARE_DATA](state, payload) {
      if (payload.isReset) {
        delete payload.isReset;
        state.share = { ...payload };
      } else {
        state.share = merge(state.share, payload);
      }
    }
  },
  actions: {
    [SET_SHARE_DATA](state, payload) {
      state.commit(SET_SHARE_DATA, payload);
    }
  }
};
