<template>
  <b-button
    size="sm"
    variant="light"
    class="btn-icon mx-1"
    @click="$emit('click')"
  >
    <i class="flaticon-eye text-primary"></i>
  </b-button>
</template>

<script>
export default {}
</script>

<style></style>
