<template>
  <b-button
    size="sm"
    variant="light-primary"
    class="mx-1 btn-hover-light-primary"
    @click="$emit('click')"
  >
    <span class="svg-icon svg-icon-md svg-icon-primary">
      <inline-svg src="media/svg/icons/Navigation/Plus.svg"></inline-svg>
    </span>
    Add
  </b-button>
</template>

<script>
export default {}
</script>

<style></style>
