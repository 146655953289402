import objectPath from "object-path"
import { TopicService, UtilsService } from "@/core/services"

export const SET_ALL_TOPICS = "setAllTopics"
export const SET_INTENTIONS = "setIntentions"
export const SET_COPY_INFO = "setCopyInfo"

export default {
  state: {
    topics: [],
    intentions: [],
    copyLinkIds: [],
  },
  getters: {
    topicData: (state) => (path, defaultValue) => {
      return objectPath.get(state, path, defaultValue)
    },
  },
  mutations: {
    [SET_ALL_TOPICS](state, payload) {
      state.topics = payload
    },
    [SET_INTENTIONS](state, payload) {
      state.intentions = payload
    },
    [SET_COPY_INFO](state, payload) {
      state.copyLinkIds = payload
    },
  },
  actions: {
    [SET_ALL_TOPICS](state, payload) {
      return new Promise(async (resolve) => {
        let response = await TopicService.get({
          cusId: payload.cusId,
          limit: 0,
        })
        state.commit(SET_ALL_TOPICS, response.results || [])
        resolve()
      })
    },
    [SET_INTENTIONS](state) {
      return new Promise(async (resolve) => {
        let response = await UtilsService.getIntentions()
        state.commit(SET_INTENTIONS, response.intentions || [])
        resolve()
      })
    },
    [SET_COPY_INFO](state, payload) {
      state.commit(SET_COPY_INFO, payload)
    },
  },
}
