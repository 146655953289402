import ApiService from "@/core/services/api.service"
import JwtService from "@/core/services/jwt.service"

// action types
export const VERIFY_AUTH = "verifyAuth"
export const LOGIN = "login"
export const LOGOUT = "logOut"
export const REGISTER = "register"
export const UPDATE_USER = "updateUser"

// mutation types
export const PURGE_AUTH = "logOut"
export const SET_AUTH = "setUser"
export const SET_ERROR = "setError"

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
}

const getters = {
  currentUser(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
}

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("auth", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.message])
        })
    })
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH)
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [VERIFY_AUTH](context) {
    return new Promise((resolve) => {
      if (JwtService.getToken()) {
        ApiService.setHeader()
        ApiService.get("auth/verify")
          .then(({ data }) => {
            context.commit(SET_AUTH, data.data)
            resolve()
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors)
            context.commit(PURGE_AUTH)
            resolve()
          })
      } else {
        context.commit(PURGE_AUTH)
        resolve()
      }
    })
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload
    const user = { email, username, bio, image }
    if (password) {
      user.password = password
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data)
      return data
    })
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true
    state.user = user
    state.errors = {}
    JwtService.saveToken(state.user.token)
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    JwtService.destroyToken()
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
