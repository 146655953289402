<template>
  <b-button
    size="sm"
    variant="light"
    class="btn-icon mx-1 btn-expand"
    @click="toggle"
  >
    <span class="svg-icon svg-icon-md svg-icon-primary">
      <inline-svg
        v-if="!isExpand"
        src="media\svg\icons\General\Expand-arrows.svg"
      ></inline-svg>
      <inline-svg
        v-if="isExpand"
        src="media\svg\icons\General\Scale.svg"
      ></inline-svg>
    </span>
  </b-button>
</template>

<script>
export default {
  props: {
    screenId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpand: false,
    }
  },
  mounted() {
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.isExpand = false
        this.fullscreen()
      }
    })
  },
  destroyed() {
    // document
    //   .getElementById(this.screenId)
    //   .removeEventListener("fullscreenchange", this.fullscreen)
    // document.getElementById(this.screenId).classList.remove("fullscreen")
  },

  methods: {
    toggle() {
      this.isExpand = !this.isExpand
      this.fullscreen()
    },
    fullscreen() {
      if (this.isExpand) {
        document.getElementById(this.screenId).classList.add("fullscreen")
        document.body.classList.add("no-scroll")
      } else {
        document.getElementById(this.screenId).classList.remove("fullscreen")
        document.body.classList.remove("no-scroll")
      }
    },
  },
}
</script>

<style>
.fullscreen {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  background: #ffffff;
}
.no-scroll {
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.no-scroll::-webkit-scrollbar {
  display: none !important;
}
body.no-scroll .fullscreen .card {
  margin-bottom: 0px;
}
</style>
